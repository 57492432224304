import React from 'react';
import PopularHotelItemDetailed from '@components/PopularHotels/PopularHotelItemDetailed';
import { PopularHotel } from '@customTypes/popular-hotel-data';

type Props = {
  popularHotels: PopularHotel[];
};

export default function HomePagePopularHotels({ popularHotels }: Props) {
  return (
    <>
      <div className="mx-auto text-3xl text-rp-primary-new pb-10 pt-10 tracking-normal px-5 text-center font-rp-pn-regular d:text-center d:px-0 d:font-rp-pn-semi-bold d:text-4xl">
        Get away for the day{' '}
        <p className="text-sm text-center font-rp-pn-regular tracking-normal py-5 d:text-base">
          Prices based on starting rates. Availability and pricing not guaranteed.
        </p>
      </div>
      <div className="flex flex-wrap flex-row items-start w-full mx-auto justify-center gap-6 gap-y-12 px-4 d:px-0 d:max-w-8xl">
        {popularHotels.slice(0, 6).map((hotel) => (
          <PopularHotelItemDetailed key={hotel.id} hotel={hotel} showLocation />
        ))}
      </div>
    </>
  );
}
