type MappingObject = {
  'Day pass': number;
  'Spa Pass': number;
  'Family Pass': number;
  Cabana: number;
  Daybed: number;
  'Pool Chair': number;
  'Beach Pass': number;
  'Spa Treatment': number;
  Massage: number;
  Facial: number;
  Nails: number;
  'Couples Spa': number;
  'Fitness Pass': number;
  'Group Package': number;
  Activity: number;
  Event: number;
  'Work Pass': number;
  'Meeting Room': number;
  Golf: number;
  'Day Room': number;
  'Kids Club': number;
  Parking: number;
  'Food & Drink': number;
};

const productSortMap: MappingObject = {
  'Day pass': 1,
  'Spa Pass': 2,
  'Family Pass': 3,
  Cabana: 4,
  Daybed: 5,
  'Pool Chair': 6,
  'Beach Pass': 7,
  'Spa Treatment': 8,
  Massage: 9,
  Facial: 10,
  Nails: 11,
  'Couples Spa': 12,
  'Fitness Pass': 13,
  'Group Package': 14,
  Activity: 15,
  Event: 16,
  'Work Pass': 17,
  'Meeting Room': 18,
  Golf: 19,
  'Day Room': 20,
  'Kids Club': 21,
  Parking: 22,
  'Food & Drink': 23,
};

export { productSortMap };
export type { MappingObject };
