import React from 'react';
import { useRouter } from 'next/router';
import PopularHotelItemDetailed from '@components/PopularHotels/PopularHotelItemDetailed';
import ButtonPrimary from '@components/common/ButtonPrimary';
import HotelApi from '@customTypes/hotel-api';
import { ExperimentType } from '@customTypes/context';
import { AMPLITUDE_EXPERIMENT_VARIANT_NAME } from '@constants/amplitudeExperiments';

type Props = {
  hotels: HotelApi[];
  selectedCityShort: string | undefined;
  selectedCityId: number | undefined;
  userCityAndState: string;
  travelerSeason?: ExperimentType;
};

function HomePageNearHotels({
  hotels,
  selectedCityShort,
  selectedCityId,
  userCityAndState,
  travelerSeason,
}: Props) {
  const router = useRouter();

  return (
    <>
      <div className="mx-auto text-3xl text-rp-primary-new pb-10 pt-10 tracking-normal px-5 text-center font-rp-pn-regular d:text-center d:px-0 d:font-medium d:text-4xl">
        {travelerSeason === AMPLITUDE_EXPERIMENT_VARIANT_NAME
          ? 'Find your next daycation near'
          : 'Get away for the day near'}{' '}
        <span className="text-rp-primary font-rp-gelica-light-italic">
          {userCityAndState || selectedCityShort || ''}
        </span>
      </div>
      <div className="flex flex-wrap flex-row items-start w-full mx-auto justify-center gap-6 gap-y-12 px-4 d:px-0 d:max-w-8xl">
        {hotels.map((hotel) => (
          <PopularHotelItemDetailed key={hotel.id} hotel={hotel} />
        ))}
      </div>

      {selectedCityId ? (
        <div className="flex flex-col items-center justify-center px-4 mt-8 d:px-0 w-full d:w-auto d:mx-auto d:mt-12">
          <ButtonPrimary
            onClick={() => router.push(`/hotel-day-passes?city_id=${selectedCityId}`)}
            classes="d:w-auto d:px-24"
          >
            See All {selectedCityShort}
          </ButtonPrimary>
        </div>
      ) : null}
    </>
  );
}

HomePageNearHotels.defaultProps = {
  travelerSeason: null,
};

export default HomePageNearHotels;
